// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useAuthStore } from "@/store/auth";

type Props<T> = Parameters<typeof $fetch<T>>;
type AsyncDataOptions<T> = Parameters<typeof useAsyncData<T>>["2"];
export const useAuthRequest = async <T>(
  request: Props<T>["0"],
  options?: Props<T>["1"],
  asyncDataOptions?: AsyncDataOptions<T>,
) => {
  const authStore = useAuthStore();
  let retry = 3;
  // TODO check if refreshing works properly
  const redirectUrl = useRoute().fullPath;
  const { data, error, execute, pending, refresh, status } =
    await useAsyncData<T>(
      `key-${request}`,
      async () => {
        const accessToken = await authStore.getAccessToken();
        return $fetch(request, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          onResponse: async ({ response }) => {
            if (retry === 0 && response.status === 401) {
              await navigateTo({
                path: "/login",
                query: { redirectUrl },
              });
              return;
            }
            retry--;
            if (response.status === 401) {
              await authStore.refreshAuthTokens().then(() => {
                refresh();
              });
            }
          },
          ...options,
        });
      },
      { server: false, ...asyncDataOptions },
    );

  return { data, error, execute, pending, refresh, status };
};
